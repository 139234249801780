@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

:root {
  --asiana-blue: #00256C;
  --asiana-red: #ED1C24;
  --asiana-yellow: #FFC726;
  --nav-height: 64px;
}

.privacy-policy-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  padding-top: calc(var(--nav-height) + 20px);
  box-sizing: border-box;
  overflow-y: auto;
  font-family: 'Noto Sans KR', sans-serif;
}

.privacy-policy-content {
  text-align: left;
  color: #333;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 20px;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 8px 32px rgba(0, 45, 115, 0.37);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.privacy-policy-title {
  color: var(--asiana-blue);
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
}

.privacy-policy-subtitle {
  color: var(--asiana-blue);
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.8rem;
}

.privacy-policy-text {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.privacy-policy-list {
  margin-bottom: 1rem;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 10px;
    padding-top: calc(var(--nav-height) + 10px);
  }

  .privacy-policy-content {
    padding: 20px;
  }

  .privacy-policy-title {
    font-size: 2rem;
  }

  .privacy-policy-subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .privacy-policy-container {
    padding-top: calc(var(--nav-height) + 5px);
  }

  .privacy-policy-title {
    font-size: 1.8rem;
  }

  .privacy-policy-subtitle {
    font-size: 1.3rem;
  }
}