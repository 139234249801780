/* global-styles.css */

:root {
  --asiana-red: #ED1C24;
  --asiana-yellow: #FFC726;
  --asiana-blue: #002D73;
  --asiana-gray: #8C8C8C;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.gradient-background {
  background: repeating-linear-gradient(
    45deg,
    var(--asiana-red) 0%,
    var(--asiana-red) 15%,
    var(--asiana-yellow) 15%,
    var(--asiana-yellow) 20%,
    var(--asiana-blue) 20%,
    var(--asiana-blue) 30%,
    var(--asiana-gray) 30%,
    var(--asiana-gray) 40%
  );
  background-size: 200% 200%;
  animation: gradientShift 20s linear infinite;
}

@keyframes gradientShift {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 100%; }
}

.content-container {
  text-align: center;
  color: var(--asiana-blue);
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 20px;
  max-width: 400px;
  box-shadow: 0 8px 32px rgba(0, 45, 115, 0.37);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s ease;
}

.content-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 45, 115, 0.5);
}

.primary-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--asiana-blue);
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: var(--asiana-red);
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
}

.route-component {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}