.google-login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #ED1C24 0%,   /* 빨간색 */
    #FFFFFF 20%,  /* 흰색 */
    #FFF200 40%,  /* 노란색 */
    #A4A4A4 60%,  /* 회색 */
    #0056A4 80%,  /* 파란색 */
    #FFFFFF 100%  /* 흰색 */
  );
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  box-sizing: border-box;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.google-login-content {
  text-align: center;
  color: #00256C;  /* 진한 남색으로 변경하여 대비 높임 */
  background: rgba(255, 255, 255, 0.8);  /* 배경 불투명도 높임 */
  padding: 40px;
  border-radius: 20px;
  max-width: 400px;
  box-shadow: 0 8px 32px rgba(0, 37, 108, 0.37);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s ease;
}

.google-login-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 37, 108, 0.5);
}

.google-login-title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
  color: #00256C;  /* 진한 남색으로 변경 */
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);  /* 텍스트 그림자 밝게 변경 */
}

.user-welcome,
.login-complete-message,
.google-login-subtitle {
  color: #00256C;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.user-welcome {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.login-complete-message {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 300;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.login-button,
.logout-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-button {
  background-color: #00256C;  /* 진한 남색으로 변경 */
  color: white;
  border: none;
}

.logout-button {
  background-color: #ffffff;
  color: #00256C;
  border: 2px solid #00256C;
}

.login-button:hover,
.logout-button:hover {
  background-color: #ED1C24;  /* 빨간색으로 변경 */
  color: white;
  border-color: #ED1C24;
}

.logout-button:hover {
  background-color: #4285F4;
  color: white;
}

.logout-button svg {
  margin-right: 8px;
}

.icon {
  margin-right: 8px;
}

.login-animation {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}