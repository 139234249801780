/* src/components/LanguageToggle.css */
.language-toggle-container {
    position: fixed;
    top: 64px;
    right: 0;
    z-index: 999;
}

.language-toggle-wrapper {
    display: flex;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 2px;
    margin: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.language-btn {
    padding: 6px 12px;
    border: none;
    background: transparent;
    color: var(--asiana-blue);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.language-btn.active {
    background: var(--asiana-blue);
    color: white;
}

.language-btn:not(.active):hover {
    background: rgba(0, 45, 115, 0.1);
}

@media (max-width: 768px) {
    .language-toggle-wrapper {
        margin: 4px;
    }

    .language-btn {
        padding: 4px 8px;
        font-size: 12px;
    }
}