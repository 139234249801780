:root {
    --asiana-blue: #002D73;  /* 추가된 변수 예시 */
    --asiana-yellow: #FFD700;
    --asiana-red: #BE1E2D;
    --asiana-gray: #333333;
}

/* 기본 컨테이너 */
.landing-container {
    min-height: 100vh;
    position: relative;
}

/* 언어 선택 버튼 */
.language-toggle {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 8px;
    z-index: 1000;
    background: white;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.lang-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background: transparent;
    color: #666;
}

.lang-btn.active {
    background: var(--asiana-blue);
    color: white;
}

/* Google 버튼 */
.google-login-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 1px;
    background: white;
    border: 1px solid #dadce0;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 200px;
}

.google-login-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1a73e8;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.google-login-button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-color: #d2e3fc;
    background: #f8fbff;
}

.google-login-button:active {
    background: #f4f8ff;
}

.google-login-button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 16px;
    position: relative;
    z-index: 1;
    width: 100%;
}

.google-icon {
    width: 18px;
    height: 18px;
}

.google-button-text {
    font-family: 'Google Sans', 'Noto Sans KR', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #3c4043;
    white-space: nowrap;
    letter-spacing: 0.25px;
}

/* 공통 섹션 스타일 */
.hero-content,
.features-content,
.steps-content,
.security-content,
.oauth-content {
    max-width: 1200px;
    margin: 0 auto;
}

.section-title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
}

.section-subtitle {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1.6;
}

/* Hero 섹션 */
.hero-section {
    padding: 60px 20px;
    text-align: center;
    background-color: var(--asiana-blue);
    color: white;
}

.hero-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.hero-subtitle {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2rem;
}

/* Features 섹션 */
.features-section {
    padding: 80px 20px;
    background-color: var(--asiana-yellow);
}

.features-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* 피처 카드 스타일 OAuth 스타일과 통일 */
.feature-card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;  /* 왼쪽 정렬로 변경 */
    text-align: left;  /* 텍스트 왼쪽 정렬 */
    height: 100%;
    gap: 12px;  /* 아이콘과 텍스트 사이 간격 */
}

.feature-card .icon {
    color: var(--asiana-blue);
    font-size: 32px;
    display: flex;
    align-items: center;
}

.feature-card h3 {
    color: var(--asiana-blue);
    margin: 0;
    font-weight: 600;
    font-size: 1.2rem;
    width: 100%;
}

.feature-card p {
    color: #666;
    line-height: 1.6;
    font-size: 1rem;
    margin: 0;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* 모바일 반응형 */
@media (max-width: 768px) {
    .features-grid {
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 15px;
    }

    .feature-card {
        flex-direction: row;  /* 가로 배치 */
        align-items: center;
        padding: 16px;
        gap: 16px;
    }

    .feature-card .icon {
        font-size: 24px;
        flex-shrink: 0;  /* 아이콘 크기 유지 */
    }

    .feature-card .content {
        flex: 1;  /* 남은 공간 차지 */
    }

    .feature-card h3 {
        font-size: 1.1rem;
        margin-bottom: 4px;
    }

    .feature-card p {
        font-size: 0.9rem;
    }
}

/* 작은 모바일 화면 */
@media (max-width: 480px) {
    .features-section {
        padding: 30px 10px;
    }

    .feature-card {
        padding: 12px;
    }

    .feature-card .icon {
        margin-right: 12px;
        font-size: 20px;
    }

    .feature-card h3 {
        font-size: 1.1rem;
    }

    .feature-card p {
        font-size: 0.9rem;
    }
}

/* OAuth 섹션 */
.oauth-section {
    background-color: #ff0000;
    padding: 80px 0;
    color: white;
}

.oauth-section .section-title {
    color: white;
}

.oauth-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 3rem;
}

/* Security 섹션 */
.security-section {
    padding: 80px 20px;
    background-color: var(--asiana-gray);
    color: white;
}

.security-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 3rem;
}

/* 카드 공통 스타일 */
.feature-card,
.step-card,
.oauth-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 45, 115, 0.1);
    text-align: center;
    transition: all 0.3s ease;
}

.feature-card:hover,
.step-card:hover,
.oauth-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.feature-card h3,
.step-card h3,
.oauth-card h3 {
    color: var(--asiana-blue);
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.feature-card p,
.step-card p,
.oauth-card p {
    color: #666;
    line-height: 1.5;
}

/* Security 카드 특별 스타일 */
.security-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 1rem;
    border: 2px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
}

.security-card:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
}

.security-card h3 {
    color: white;
}

.security-card p {
    color: rgba(255, 255, 255, 0.9);
}

/* Footer */
.landing-footer {
    background-color: white;
    padding: 3rem 0;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

.footer-section h3 {
    color: var(--asiana-blue);
    margin-bottom: 1rem;
    font-weight: bold;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section li {
    margin-bottom: 0.5rem;
}

.footer-section a {
    color: #666;
    text-decoration: none;
    transition: color 0.2s ease;
}

.footer-section a:hover {
    color: var(--asiana-blue);
}

.footer-bottom {
    margin-top: 3rem;
    padding-top: 1.5rem;
    border-top: 1px solid #eee;
    text-align: center;
    color: #666;
}

/* 반응형 디자인 */
@media (max-width: 1024px) {
    .features-grid,
    .oauth-grid,
    .security-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .features-grid,
    .oauth-grid,
    .security-grid,
    .footer-content {
        grid-template-columns: 1fr;
    }

    .language-toggle {
        top: 10px;
        right: 10px;
    }

    .lang-btn {
        padding: 6px 12px;
        font-size: 12px;
    }

    .section-title {
        font-size: 1.75rem;
    }

    .features-grid {
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 15px;
    }

    .schedule-section,
    .crew-section {
        padding: 60px 0;
    }

    .hero-section {
        padding: 40px 15px;
    }

    .features-section {
        padding: 20px 10px;
    }

    /* 버튼 스타일 */
    .hero-section button {
        width: 100% !important;
        margin: 8px 0 !important;
        padding: 12px 20px !important;
    }

    /* 피처 카드 스타일 */
    .feature-card {
        padding: 20px;
        flex-direction: row;  /* 모바일에서는 가로 배치 */
        text-align: left;
        gap: 15px;
    }

    .feature-card .icon {
        flex-shrink: 0;  /* 아이콘 크기 유지 */
    }

    .feature-card h3 {
        margin: 0 0 5px 0;
    }

    /* 여백과 패딩 최적화 */
    .section {
        padding: 30px 15px !important;
        margin: 10px 0 !important;
    }

    .hero-buttons {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
    }

    .google-login-button,
    .app-download-button {
        width: calc(100% - 20px);
        max-width: 300px;
        margin: 5px 10px;
    }

    .google-login-button-inner,
    .app-download-button-inner {
        padding: 10px 12px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .hero-title {
        font-size: 1.75rem;
    }

    .hero-subtitle {
        font-size: 1rem;
    }

    .hero-section button {
        padding: 10px 16px !important;
        font-size: 14px !important;
    }

    .feature-card {
        padding: 12px !important;
        margin: 8px !important;
    }

    .google-login-button,
    .app-download-button {
        width: calc(100% - 16px);
        margin: 4px 8px;
    }

    .google-login-button-inner,
    .app-download-button-inner {
        padding: 8px 12px;
        font-size: 14px;
    }
}

.oauth-card {
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.oauth-card h3 {
    color: #333;
    margin-bottom: 12px;
    font-weight: 600;
}

.oauth-card p {
    color: #666;
    line-height: 1.6;
}

.oauth-card .icon {
    color: #ff0000;
    margin-bottom: 16px;
}

.oauth-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

/* 새로운 섹션 스타일 추가 */
.schedule-section {
    background-color: var(--background-light);
    padding: 80px 0;
}

.crew-section {
    background-color: white;
    padding: 80px 0;
}

/* 기존 스타일 수정 */
.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 40px;
}

.feature-card {
    background: white;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

/* 스크린샷 섹션 스타일 */
.app-preview {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 10px;
}

.screenshots-row {
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 10px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    /* 스크롤바 숨기기 */
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.screenshots-row::-webkit-scrollbar {
    display: none;
}

.screenshot-item {
    flex: 0 0 200px;  /* 데스크톱에서는 더 크게 */
    min-width: 200px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background: white;
    scroll-snap-align: center;
}

.screenshot-item img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

/* 태블릿 */
@media (max-width: 1024px) {
    .screenshot-item {
        flex: 0 0 160px;
        min-width: 160px;
    }
}

/* 모바일 반응형 */
@media (max-width: 768px) {
    .app-preview {
        margin: 10px auto;
        padding: 0 5px;
    }

    .screenshots-row {
        gap: 8px;
        padding: 5px 0;
    }

    .screenshot-item {
        flex: 0 0 100px;  /* 모바일에서는 작게 */
        min-width: 100px;
        border-radius: 8px;
    }
}

/* 작은 모바일 화면 */
@media (max-width: 480px) {
    .screenshot-item {
        flex: 0 0 80px;  /* 더 작은 화면에서는 더 작게 */
        min-width: 80px;
        border-radius: 6px;
    }

    .screenshots-row {
        gap: 6px;
    }
}

/* 스크린샷 호버 효과 개선 */
.screenshot-item:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.feature-showcase-image img {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.hero-buttons {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 24px;
    flex-wrap: wrap;
}

.app-download-button {
    background: white;
    border: none;
    border-radius: 8px;
    padding: 1px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 200px;
}

.app-download-button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 16px;
    color: var(--asiana-blue);
    font-weight: 500;
}

.app-download-button:hover {
    background: #f8f9fa;
    transform: translateY(-1px);
}

.coming-soon-alert {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--asiana-blue);
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.coming-soon-alert svg {
    color: #FFD700;
}

/* 모바일 대응 */
@media (max-width: 480px) {
    .hero-buttons {
        gap: 8px;
    }

    .app-download-button {
        min-width: 130px;
    }

    .app-download-button-inner {
        padding: 6px 12px;
    }

    .app-download-button-inner span {
        font-size: 14px;
    }
}

/* 스크린샷 섹션 모바일 최적화 */
@media (max-width: 768px) {
    .screenshots-row {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        gap: 12px;
        padding: 10px 0;
        margin: 0 -15px;
    }

    .screenshot-item {
        scroll-snap-align: start;
        flex: 0 0 80%;
        min-width: 280px;
        max-width: none;
        margin: 0 15px;
    }

    .screenshot-item img {
        width: 100%;
        height: auto;
        border-radius: 12px;
    }
}

/* 피처 카드 그리드 개선 */
.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
}

/* 모바일에서 피처 카드 레이아웃 */
@media (max-width: 768px) {
    .features-grid {
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 15px;
    }

    .feature-card {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 15px;
        padding: 15px;
        text-align: left;
    }

    .feature-card .icon {
        font-size: 24px;
    }

    .app-download-button {
        width: calc(100% - 20px);
        max-width: 300px;
        margin: 10px;
    }

    .app-download-button-inner {
        padding: 10px 12px;
        font-size: 14px;
    }

    .hero-buttons {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

/* 작은 모바일 화면에서 추가 최적화 */
@media (max-width: 480px) {
    .screenshot-item {
        flex: 0 0 90%;
        min-width: 250px;
    }

    .feature-card {
        padding: 12px;
        gap: 12px;
    }

    .app-download-button {
        width: calc(100% - 16px);
        margin: 8px;
    }
}