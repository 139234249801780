@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

:root {
  --asiana-blue: #00256C;
  --asiana-red: #ED1C24;
  --asiana-yellow: #FFC726;
  --nav-height: 64px; /* 네비게이션 바의 높이, 실제 높이에 맞게 조정 필요 */
}

.app-vision-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: calc(var(--nav-height) + 20px); /* 네비게이션 바 높이 + 추가 여백 */
  box-sizing: border-box;
  overflow-y: auto;
  font-family: 'Noto Sans KR', sans-serif;
}

.app-vision-content {
  text-align: center;
  color: #333;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 20px;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 8px 32px rgba(0, 45, 115, 0.37);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.app-vision-title {
  color: var(--asiana-blue);
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 2.5rem;
}

.app-vision-subtitle {
  color: var(--asiana-blue);
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 1.8rem;
}

.features-grid {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.feature-card {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 37, 108, 0.2);
}

.icon-wrapper {
  background-color: var(--asiana-blue);
  color: white;
  padding: 15px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.future-vision {
  padding: 20px;
  margin-top: 2rem;
  background-color: var(--asiana-blue);
  color: white;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .app-vision-container {
    padding: 10px;
    padding-top: calc(var(--nav-height) + 10px);
    align-items: flex-start;
  }

  .app-vision-content {
    padding: 20px;
  }

  .app-vision-title {
    font-size: 2rem;
  }

  .app-vision-subtitle {
    font-size: 1.5rem;
  }

  .feature-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .app-vision-container {
    padding-top: calc(var(--nav-height) + 5px);
  }

  .app-vision-title {
    font-size: 1.8rem;
  }

  .app-vision-subtitle {
    font-size: 1.3rem;
  }

  .feature-card {
    padding: 10px;
  }
}