@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

.schedule-main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Noto Sans KR', sans-serif;
}

.schedule-main > div {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 64px);
  padding-top: 80px;
  box-sizing: border-box;
}

.schedule-main h3 {
  color: white;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.schedule-main > div > div {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.schedule-main .MuiCard-root {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.15);
}

.schedule-main .MuiCard-root:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0,0,0,0.2);
}

.schedule-main .MuiCardMedia-root {
  height: 200px;
  background-size: cover;
  background-position: center;
}

.schedule-main .MuiCardContent-root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
}

.schedule-main .MuiTypography-h5 {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #333;
}

.schedule-main .MuiTypography-body2 {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 1200px) {
  .schedule-main > div > div {
    max-width: 900px;
  }
}

@media (max-width: 960px) {
  .schedule-main > div > div {
    max-width: 600px;
  }
  
  .schedule-main h3 {
    font-size: 2.2rem;
  }
}

@media (max-width: 600px) {
  .schedule-main > div {
    padding: 1rem;
    padding-top: 70px;
  }

  .schedule-main h3 {
    font-size: 2rem;
  }

  .schedule-main .MuiCard-root {
    max-width: 350px;
    margin: 0 auto;
  }

  .schedule-main .MuiCardMedia-root {
    height: 180px;
  }
}