@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

.excel-upload-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.main-paper {
  margin: 20px auto;
  width: 90%;
  max-width: 800px;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 45, 115, 0.37);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s ease;
}

.main-paper:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 45, 115, 0.5);
}

.title {
  text-align: center;
  color: var(--asiana-blue);
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 2.5rem;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #00256C; /* Asiana 곤색 */
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background-color: #001F5C; /* 호버 시 약간 어두운 곤색 */
}

.action-button:active {
  transform: translateY(1px);
}

.action-button.Mui-disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #99A3B7; /* 비활성화 시 밝은 곤색 */
}
.action-button.secondary-button {
  background-color: #4A90E2; /* 밝은 파란색 */
  color: white;
}

.action-button.secondary-button:hover {
  background-color: #3A7BC8; /* 호버 시 약간 어두운 파란색 */
}
@media (max-width: 768px) {
  .excel-upload-container {
    padding-top: 56px;
  }

  .main-paper {
    width: 95%;
    padding: 1.5rem;
  }

  .title {
    font-size: 2rem;
  }

  .action-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .main-paper {
    padding: 1rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .action-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}