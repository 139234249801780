/* NavigationBar.css */
.navigation-bar {
  background-color: var(--asiana-blue);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navigation-bar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 64px;
}

.navigation-bar-logo {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s;
}

.navigation-bar-logo:hover {
  opacity: 0.8;
}

.navigation-bar-menu {
  display: flex;
  align-items: center;
}

.navigation-bar-menu-item {
  color: white;
  text-decoration: none;
  padding: 0 16px;
  height: 64px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.navigation-bar-menu-item:hover,
.navigation-bar-menu-item:focus {
  background-color: rgba(255,255,255,0.1);
}

.google-login-button {
  background-color: white;
  color: var(--asiana-blue);
  padding: 8px 16px;
  border-radius: 4px;
  margin-left: 16px;
  height: auto;
}

.google-login-button:hover {
  background-color: #f0f0f0;
}

.navigation-bar-menu-icon {
  display: none;
  color: white;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 20px;
}

.dropdown {
  position: relative;
}

.dropdown span {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 64px;
  left: 0;
  background-color: var(--asiana-blue);
  min-width: 200px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.dropdown-content.show {
  display: block;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

.dropdown-content a:hover {
  background-color: rgba(255,255,255,0.1);
}

.nav-auth-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 16px;
}

.nav-auth-button.login {
  background: transparent;
  border: 1px solid white;
  color: white;
}

.nav-auth-button.logout {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

.nav-auth-button:hover {
  background: rgba(255, 255, 255, 0.2);
}
/* 기존 CSS에 추가 */
.navigation-bar-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.language-selector {
  display: flex;
  gap: 2px;
  background: rgba(255, 255, 255, 0.1);
  padding: 2px;
  border-radius: 4px;
}

.language-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background: transparent;
  color: white;
}

.language-btn.active {
  background: white;
  color: var(--asiana-blue);
}

.language-btn:not(.active):hover {
  background: rgba(255, 255, 255, 0.2);
}

/* 모바일 언어 선택 */
.mobile-language-selector {
  display: none;
  padding: 16px;
  gap: 8px;
}

@media (max-width: 768px) {
  .navigation-bar-menu {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 64px;
      right: -250px;
      width: 250px;
      height: calc(100vh - 64px);
      background-color: var(--asiana-blue);
      box-shadow: -2px 0 4px rgba(0,0,0,0.1);
      transition: right 0.3s ease-in-out;
      overflow-y: auto;
  }

  .navigation-bar-menu.active {
      display: flex;
      right: 0;
  }

  .navigation-bar-menu-item {
      padding: 12px 16px;
      height: auto;
      border-bottom: 1px solid rgba(255,255,255,0.1);
  }

  .google-login-button {
      margin: 16px;
  }

  .dropdown-content {
      position: static;
      box-shadow: none;
      background-color: rgba(255,255,255,0.05);
      width: 100%;
  }

  .dropdown-content a {
      padding: 10px 32px;
      font-size: 14px;
  }

  .navigation-bar-menu-icon {
      display: block;
  }

  .nav-auth-button {
      margin: 12px 16px;
      width: calc(100% - 32px);
      padding: 10px;
      text-align: center;
      font-size: 14px;
  }

  .language-selector {
    display: none;
}

.mobile-language-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12px 16px;
    gap: 8px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.mobile-language-selector .language-btn {
    flex: 1;
    max-width: 100px;
    padding: 8px 12px;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
}

.navigation-bar-menu::-webkit-scrollbar {
    width: 4px;
}

.navigation-bar-menu::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);
}

.navigation-bar-menu::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.3);
    border-radius: 2px;
}
}

body {
  padding-top: 64px;
}