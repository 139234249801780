@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

.feedback-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 64px; /* NavigationBar의 높이만큼 상단 패딩 추가 */
    box-sizing: border-box;
}

.feedback-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
}

.main-paper {
    margin: 20px auto;
    width: 90%;
    max-width: 600px;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 45, 115, 0.37);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: all 0.3s ease;
}

.main-paper:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 45, 115, 0.5);
}

.MuiTypography-h4 {
    text-align: center;
    color: var(--asiana-blue);
    margin-bottom: 2rem !important;
    font-weight: 500 !important;
    font-size: 2.5rem !important;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.MuiButton-root {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 50px;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: var(--asiana-blue);
    color: white;
    border: none;
}

.MuiButton-root:hover {
    background-color: var(--asiana-red);
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.MuiTextField-root, .MuiSelect-root {
    transition: all 0.3s ease-in-out;
}

.MuiTextField-root:hover, .MuiSelect-root:hover {
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .feedback-container {
        padding-top: 56px;
    }

    .main-paper {
        width: 95%;
        padding: 1.5rem;
    }

    .MuiTypography-h4 {
        font-size: 2rem !important;
    }

    .MuiButton-root {
        padding: 8px 16px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .main-paper {
        padding: 1rem;
    }

    .MuiTypography-h4 {
        font-size: 1.8rem !important;
    }
}