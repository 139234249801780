.share-place-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/public/crew_main.WEBP'); /* 배경 이미지 경로 설정 */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.share-place-content {
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    padding: 20px;
    border-radius: 10px;
    max-width: 90%; /* 반응형 크기 조절 */
    box-sizing: border-box;
}

.share-place-title {
    margin-bottom: 20px;
}
